.industriesBanner{
    background-image: url('./banner.jpg');
    height: 50vh;
    background-size: cover;
}
.industriesBanner .w-75{
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}
.video-text-continer div p{
    font-weight: 200;
    font-style: italic;

}