.industryCard{
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    cursor: pointer;
}
.industryCard .title{
    position:absolute;
    top:50%;
    left:50%;
    width:100%;
    text-align:center;
    transform: translate(-50%, -50%);
    font-size:35px;
    color: white;
}
.industryCard .overlay{
    position:absolute;
    height:100%;
    width:100%;
    background-color:black;
    transform: scaleY(0);
    transition: 0.5s ease;
    transform-origin: bottom;
}
.industryCard .overlay .overlay-text{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%)
}
.industryCard:hover .overlay{
    transform: scaleY(1);

}
.overlay-text b{
    position:relative;
}
.overlay-text b::after{
    content:"";
    position:absolute;
    bottom: -6px;
    left:0;
    height: 2px;
    width:100%;
    background-color:rgb(255,229,43);
    transform:scaleX(0);
    transition: 0.5s
}
.overlay-text b:hover::after{
    transform:scaleX(1)

}