.DumbCard{
    width:31%;
    height: 40vh;
    position: relative;
    cursor: pointer;
    transition: all 0.5s ease
}

.dumbcardText{
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
}
.margin{
    margin-left: 3.5%;
    margin-right:3.5%;
}
