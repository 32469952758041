*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    overflow-x: hidden;
}
.bg-blue{
    background-color:black;
}
.mainBanner{
    height: 65vh;
    width: 100%;
    background-image: url('./Banner.jpg');
    background-size: cover;
    background-position: center;
}
.mainBannerText{
    width:75%;
    margin: auto;
    position:relative;
    top:50%;
    transform: translateY(-50%);
}
.scrollspy{
    width:75%;
    margin: auto;
    background-color:rgba(255, 255, 255, 0.8);
}
.scrollspy ul{
    list-style-type: none;
    width: 40%;
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    padding: 6px
}
.scrollspy ul li a{
    font-weight:bold;
    color:black;
    position:relative;
}
.scrollspy ul li a::after{
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: rgb(255,229,43);
    transform: scaleX(0);
}
.scrollspy ul li a:hover{
    text-decoration: none;
}
.scrollspy ul li a:hover::after{
    transform: scaleX(1);

}
.scrollspyActive{
    border-bottom:4px solid rgb(255,229,43)
}
.industryCardDiv{
    display:flex;
}
.industryCardDiv .div1{
    min-width: 47.5%;
    margin-right: 2.5%;
    
}
.industryCardDiv .div2{
    min-width: 47.5%;
    margin-left: 2.5%;
}
.useCaseTab{
    background-color: black;
    color: white;
}
.tabPanel{
    display:flex;
    width: 90%;
    margin: auto;
    justify-content:space-between;
    text-align: center;
    font-weight: bold;
}
.tabPanel .panel{
    flex:1;
    padding: 16px;
    position: relative;
    cursor: pointer;
}

.panel.active{
    color:rgb(255,229,43)
}

.panel.active::after, .panel::after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width:100%;
    height: 4px;
    background-color:rgb(255,229,43);
    transition: 0.5s ease;
    transform-origin: left;
}
.panel.active::after, .panel:hover::after{
    transform: scaleX(1);
}
.panel::after{
    transform: scaleX(0);
}
.collection{
    display:flex;
    flex-wrap: wrap;
}
.joinNowBanner{
    width: 100%;
    height: 90vh;
    background-color:rgb(255,229,43);
    position: relative;
    overflow: hidden;
}
.joinNowBanner h2{
    width: 50%;
    position: absolute;
    left:5%;
    top: 30%
}
.computerImg{
    position: absolute;
    left:60%;
    bottom:0;
}
.joinNowBanner div button{
    font-size:20px;
    background-color:transparent;
    border:1px solid black;
    padding:10px;
    width:200px;
    cursor:pointer;
    position: relative;
}
.joinNowBanner div button::after{
    content: '';
    position:absolute;
    top:0;
    left:0;
    background-color:black;
    height: 100%;
    width: 100%;
    transform: scale(0);
    z-index: 99;
    transition: 0.31s ease;
}
.joinNowBanner h2 div button span{
    position: relative;
    z-index: 100;
    font-weight: bold;
}
.joinNowBanner div button:hover>span{
    color:white
}
.joinNowBanner div button:hover::after{
    transform: scale(1);
}
