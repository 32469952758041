element.style {
  background-image: url(https://www.h2o.ai/wp-content/uploads/2021/01/mesh-2.png);
  visibility: visible;
  animation-delay: 0.2s;
  animation-name: wefadeInUp;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.button-tiles {
  z-index: 2;
  height: auto;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
section.button-tiles {
  background-color: #f8f8f8;
}
div {
  display: block;
}

.text-center {
  text-align: center;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1150px;
  position: relative;
}

header:after,
.container:after,
.wrapper:after,
.column-wrapper:after,
.grid-wrapper:after,
.two-third:after,
.content-wrapper:after,
.v-middle-wrapper:after,
.col-wrapper:after {
  content: '';
  clear: both;
  display: block;
}

.button-tile-content .text-center h2 {
  font-size: 36px !important;
}

h2 {
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.25;
  font-family: 'motiva-sans', sans-serif;
  font-weight: 700;
}
.text-center {
  text-align: center;
}
.button-tiles .button-tile-content h5 {
  font-size: 16px;
}

h5 {
  display: block;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.5;
  font-family: 'motiva-sans', sans-serif;
  font-weight: 700;
}
.button-tiles .button-tile-content div.tiles {
  margin-top: 70px !important;
  margin-bottom: 100px !important;
}
.button-tiles .col-outer {
  width: calc(100% + 28px);
  margin: 0 -14px;
}
.button-tiles .col-outer:after {
  display: block;
  content: '';
  clear: both;
}
.button-tiles .tile-col {
  display: inline-block;
  padding: 22px;
  position: relative;
}
.button-tiles .tile-col .tileicon img {
  height: 110px;
  width: 110px;
}
img {
  max-width: 100%;
  vertical-align: top;
  border: 0;
}
.button-tiles .tile-col .tileTitle {
  font-size: 12px;
  font-weight: bold;
}

.text-center {
  text-align: center;
}
.button-tiles .tile-col div.hoverText {
  background: black;
  color: white;
  padding: 25px 25px 10px 15px;
  border-radius: 0 0 15px 0;
  width: 200px;
  position: absolute;
  left: -24px;
  z-index: 1000;
  box-shadow: -8px 6px 13px 1px rgb(0 0 0 / 40%);
}
.button-tiles .tile-col div.hoverText {
  min-height: 260px;
  top: -55px !important;
}
.button-tiles .tile-col div.hoverText {
  text-align: left;
}
.button-tiles .tile-col div.hoverText .hoverTitle {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
}

.button-tiles .tile-col div.hoverText {
  color: white;
}
.button-tiles .tile-col div.hoverText {
  text-align: left;
}
.button-tiles .tile-col div.hoverText {
  text-align: left;
}
.button-tiles img.hoverIcon {
  width: 50px;
  height: auto;
  margin-top: -25px;
  margin-right: -15px;
  float: right;
}
img {
  max-width: 100%;
  vertical-align: top;
  border: 0;
}
.button-tiles .tile-col div.hoverText .hoverTitle {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}
.button-tiles .tile-col div.hoverText {
  text-align: left;
}
.button-tiles .tile-col div.hoverText {
  text-align: left;
}
.button-tiles .tile-col div.hoverText div.hoverContent p {
  font-size: 14px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.25;
  font-family: 'proxima-nova', sans-serif;
}
.button-tiles .tile-col div.hoverText {
  text-align: left;
}
.button-tiles .tile-col div.hoverText .hoverCTA {
  padding: 10px 0;
}
.button-tiles .tile-col div.hoverText {
  text-align: left;
}
.we-small-height p {
  font-size: 22px;
}
.button-tiles .tile-col div.hoverText {
  text-align: left;
}
.button-tiles .tile-col div.hoverText .hoverCTA a {
  color: white;
  font-size: 12px;
  font-weight: bold;
}
p > a {
  display: inline;
  line-height: 1;
  padding: 0;
  position: relative;
}
a {
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  font-family: 'proxima-nova', sans-serif;
}
p > a:before {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: #008cff;
  width: 0;
  height: 2px;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.button-tiles .tile-col div.hoverText .hoverCTA a:after {
  content: ' >';
}
.button-tiles .col-outer:after {
  display: block;
  content: '';
  clear: both;
}

.button-tiles .col-outer {
  width: calc(100% + 28px);
  margin: 0 -14px;
}
.button-tiles .col-outer {
  width: calc(100% + 28px);
  margin: 0 -14px;
}
.button-tiles .button-tile-content div.lower_section h2 {
  margin-bottom: 45px;
}
.button-tile-content .text-center h2 {
  font-size: 36px !important;
}
h2 {
  line-height: 1.25;
  font-family: 'motiva-sans', sans-serif;
  font-weight: 700;
}
.button-tiles .col-outer .col-four {
  background: white;
  padding: 14px 24px 24px 24px;
  margin: 10px 15px;
  width: calc(20% + 25px);
  min-height: 80px;
}
.col-four {
  float: left;
  position: relative;
}
.text-center .underline-yellow:before,
.text-center .underline-black:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.underline-yellow:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -9px;
  background: #ffe52b;
  width: 65px;
  height: 4px;
}
.we-small-height h6 {
  font-size: 16px;
  font-weight: normal;
}
.underline-yellow {
  position: relative;
}
h6 {
  line-height: 1.33;
  font-family: 'motiva-sans', sans-serif;
}
h6 {
  display: block;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.text-center .underline-yellow:before,
.text-center .underline-black:before {
  left: 50%;
}
.underline-yellow:before {
  content: '';
  position: absolute;
  bottom: -9px;
  background: #ffe52b;
  width: 65px;
  height: 4px;
}
.underline-yellow:before {
  content: '';
  position: absolute;
  bottom: -9px;
  background: #ffe52b;
  width: 65px;
  height: 4px;
}
.button-tiles .col-outer:after {
  display: block;
  content: '';
  clear: both;
}
.button-tiles .button-tile-content .lower_section .lower_content {
  margin: 50px 0;
}
.button-tile-content .lower_content h6 {
  font-weight: bold;
}
.we-small-height h6 {
  font-size: 16px;
}
.btn-yellow,
.btn-black {
  border: none;
  padding: 12px 32px 11px 30px;
}
.btn-default,
.btn-yellow,
.btn-black {
  color: #000000;
  margin-top: 10px;
  font-weight: 700;
  font-size: 14px;
  font-family: 'proxima-nova', sans-serif;
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 0;
  background: none;
  transition: all 0.4s ease;
  letter-spacing: -0.019em;
}
a {
  text-decoration: none;
  outline: 0;
}
.btn-yellow:before,
.btn-black:before {
  z-index: -1;
  background-color: #ffe52b;
  border: none;
}
.btn-default:before,
.btn-yellow:before,
.btn-black:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.btn-yellow:after,
body.h2o .btn-black:after {
  top: -2px;
  left: -2px;
  z-index: 1;
  opacity: 0;
  background-color: transparent;
  pointer-events: none;
  border: 2px solid #000000;
  transform: scale(1, 1);
}
.btn-default:after,
.btn-yellow:after,
.btn-black:after {
  content: '';
  position: absolute;
}
.btn-yellow:before,
.btn-black:before {
  z-index: -1;
  background-color: #ffe52b;
  border: none;
}
.btn-default:before,
.btn-yellow:before,
.btn-black:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.btn-default,
.btn-yellow,
.btn-black {
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  font-family: 'proxima-nova', sans-serif;
  cursor: pointer;
  letter-spacing: -0.019em;
}
.btn-yellow:after,
.btn-black:after {
  top: -2px;
  left: -2px;
  z-index: 1;
  opacity: 0;
  background-color: transparent;
  pointer-events: none;
  border: 2px solid #000000;
  transition: all 0.3s ease;
  transform: scale(1, 1);
}
.btn-default:after,
.btn-yellow:after,
.btn-black:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}
header:after,
.container:after,
.wrapper:after,
.column-wrapper:after,
.grid-wrapper:after,
.two-third:after,
.content-wrapper:after,
.v-middle-wrapper:after,
.col-wrapper:after {
  content: '';
  clear: both;
  display: block;
}
